import { apiBaseRequest } from "./apiBase"

export const apiAWS = {
  CreateUser: "apiCreateUser",
  DeleteUser: "apiDeleteUser",
  Subscribe: "apiSubscribe",
  Unsubscribe: "apiUnsubscribe",
  ReloadTopics: "apiReloadTopics",
  ReloadPermissions: "apiReloadPermissions"
}

export const restapiUserCreate = async (owner, email) => {
  if (owner == null || email == null) {
    console.error('Rest Api: Failed request on create user: empty params')
    return null
  }
  const payload = {
    owner: owner,
    attributes: {
      email: email
    }
  };
  return await apiBaseRequest('/api', apiAWS.CreateUser, payload);
};

export const restapiChannelSubscribe = async (owner, channelId, extraId, workspaceId) => {
  if (owner == null || (channelId == null && extraId == null) || workspaceId == null) {
    console.error('Rest Api: Failed request on subscribe: empty params')
    return null
  }
  const payload = {
    owner: owner,
    attributes: {
      channelId: channelId,
      extraId: extraId,
      workspaceId: workspaceId
    }
  };
  return await apiBaseRequest('/api', apiAWS.Subscribe, payload);
};

export const restapiChannelUnsubscribe = async (owner, channelId, extraId) => {
  if (owner == null || (channelId == null && extraId == null)) {
    console.error('Rest Api: Failed request on unsubscribe: empty params')
    return null
  }
  const payload = {
    owner: owner,
    attributes: {
      channelId: channelId,
      extraId: extraId
    }
  };
  return await apiBaseRequest('/api', apiAWS.Unsubscribe, payload);
};

export const restapiAWSRequest = async (action, payload) => {
  if (action == null || payload == null) {
    console.error('Rest Api: Failed aws request: empty params');
    return null;
  }
  return await apiBaseRequest('/api', action, payload);
};