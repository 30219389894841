

export const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length 
    && Object.keys(o1).every(p => o1[p] === o2[p])

export const isBlankOrNull = (str) => {
  return (!str || /^\s*$/.test(str) || !str?.trim());
}

export const stipDomainUrls = (str) => {
  return str?.replace(/((http|https|ftp|ftps)\:\/\/)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g, '')
}

export const displayDate = (datetime, hideTime) => {
  var result = "Empty"
  if (datetime == null) return result
  try {
    result = hideTime === true ? 
    new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    }).format(new Date(datetime))
  :
    new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }).format(new Date(datetime))  
  } catch (err) {
    console.error('Invalid date conversion', err)
  } 
  return result 
}

export const randomCode = () => {
  var code = ""
  var codeLength = 4
  var random = [0,1,2,3,4,5,6,7,8,9]
  for(var i = 0; i < codeLength; i++) {
    var index = Math.floor(Math.random()*10)
    code += random[index]
  }
  return code
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
// !this.props.categories
// this.props.default_category
export const filterMessages = ({tags, messages, channel, categories, default_category}) => {
  return messages.list.filter(msg => channel?.filterRules?.some(rule => !rule.disable &&
      // (channel?.id === rule.channelId || subsChannels?.some(subsCh => subsCh?.id === rule.channelId)) &&
      rule.channelId === msg.channelId &&
      (rule.categories?.some(category => category === msg.content?.category && categories.some(cat => cat?.id === msg.content?.category)) ||
        (!categories.some(cat => cat?.id === msg.content?.category) && rule.categories?.some(category => category === default_category?.id)) ||
        rule.labels?.some(filterLabel => tags?.list?.some(tag => tag.tagMessageId === msg.id && tag.label?.name === filterLabel))
      )
    )
  )
}

export const getElementsUnread = ({ lastReadedTime, messages }) => {
  if(lastReadedTime) {
    return messages.reduce((acc, { createdAt }, i) => {
      return {
        ...acc,
        lastIndex: createdAt === lastReadedTime ? i : acc.lastIndex,
        quantity: new Date(createdAt) > new Date(lastReadedTime) ? acc.quantity + 1 : acc.quantity,
      }
    }, {lastIndex: messages.length - 1, quantity: 0})
  } else {
    return {
      lastIndex: null,
      quantity: 0
    }
  }
}

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '');
  
  if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }    
  
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return {r:r, g:g, b:b, a:opacity/100};
}

export const generateThumbnail = (file, boundBox) => {
  if (!boundBox || boundBox.length !== 2){
    throw "You need to give the boundBox"
  }
  var reader = new FileReader();
  var canvas = document.createElement("canvas")
  var ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    reader.onload = function(event){
        var img = new Image();
        img.onload = function(){
            var scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
            let w = img.width*scaleRatio
            let h = img.height*scaleRatio
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(img, 0, 0, w, h);
            return resolve(canvas.toDataURL(file.type))
        }
        img.onerror = reject
        img.src = event.target.result;
    }
    reader.onerror = reject
    reader.readAsDataURL(file);
  })
}

export const serialize = (obj, prefix) => {
  let str = [], p;
  for(p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const slugify = (text) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')


export const navigateToExternalUrl = (url, shouldOpenNewTab) =>
    shouldOpenNewTab === true ? url && window.open(url, "_blank") : window.location.href = url;

export const getPlatform = () => {
  const userDeviceArray = [
    {device: 'Android', platform: /Android/},
    {device: 'iPhone', platform: /iPhone/},
    {device: 'iPad', platform: /iPad/},
    {device: 'Symbian', platform: /Symbian/},
    {device: 'Windows Phone', platform: /Windows Phone/},
    {device: 'Tablet OS', platform: /Tablet OS/},
    {device: 'Linux', platform: /Linux/},
    {device: 'Windows', platform: /Windows NT/},
    {device: 'Macintosh', platform: /Macintosh/}
  ];
  return userDeviceArray.find(dev => dev?.platform?.test(navigator.userAgent))?.device ?? 'Windows'
}

export const sortedItemsByDate = (items, desc) => {
  const result = desc ?
    items?.sort(function(a,b){ return new Date(b?.createdAt) - new Date(a?.createdAt); }) :
    items?.sort(function(a,b){ return new Date(a?.createdAt) - new Date(b?.createdAt); })
  return result ?? items
}

export const convertBase64 = (file) => {
  if (file == null) { return }
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}

export const hashStr = (str) => {
  var hash = 5381
  var i = str.length

  while(i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  const result = hash >>> 0;
  return String(result)
}

export const isEmail = (email) => {
  if (isBlankOrNull(email)) {
    return  false
  }
  const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return pattern.test(email)
}

export const hideEmail = (email) => {
  var censorWord = function (str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  var censorEmail = function (email){
    var arr = email.split("@");
    return censorWord(arr[0]) + "@" + censorWord(arr[1]);
  }

  return !isEmail(email) ? email : censorEmail(email)
}

export const humanFileSize = (bytes, si) => {
  try {
    var thresh = si ? 1000 : 1024
    if(Math.abs(bytes) < thresh) return bytes + ' B'
    var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB']
    var u = -1
    do {
      bytes /= thresh
      ++u
    } while(Math.abs(bytes) >= thresh && u < units.length - 1)
    return bytes.toFixed(1)+' '+units[u]
  } catch (e) {
    console.error('error parsing bytes', e)
    return String(bytes)
  }
}

export const loadBrokerAddress = (data) => {
  try {
    const json = JSON.parse(data)
    let mtVersion = json["mtVersion"]
    let brokerId = json["brokerId"]
    let serviceId = json["serviceId"]
    return { mtVersion, brokerId, serviceId }
  } catch (e) {
    console.error("tradesync default broker is empty")
  }
  return null
}

export const saveBrokerAddress = (mtVersion, brokerId, serviceId) => {
  return JSON.stringify({
    mtVersion: mtVersion,
    brokerId: brokerId,
    serviceId: serviceId
  })
}